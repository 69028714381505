.ShowMoreLink {
    padding: 0 !important;
    font-weight: 700;
    font-family: var(--textFont);
    text-decoration: none;

    :global(.theme-vp) &,
    :global(.theme-vpbe) &,
    :global(.theme-vn) & {
        color: rgb(var(--linkColor));
    }

    :global(.theme-gp) &,
    :global(.theme-vi) & {
        color: rgb(var(--mainColor));
    }

    &:hover {
        color: rgb(var(--mainColor)) !important;

        :global(.theme-vi) & {
            color: rgb(var(--mainColorLighter)) !important;
        }
    }

    :global(.theme-gp) & {
        text-transform: uppercase;
        letter-spacing: 0.4px;
    }
}
