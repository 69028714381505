@use 'mixins';

$lineHeight: 1.25em;
$borderWidth: var(--thickerBorderWidth);

.FeaturedItem {
    display: flex;
    flex-direction: row;
    min-height: $lineHeight;
    margin: 0 var(--standardPadding);
    padding: var(--standardPadding);
    background-color: rgb(var(--lightSectionBack));
    cursor: pointer;
    border-left: $borderWidth solid rgb(var(--mainColor));
    border-radius: var(--standardRadius);

    :global(.theme-br) &,
    :global(.theme-gp) & {
        @extend %block-shadow;
    }

    :global(.theme-vp) & {
        border-left: $borderWidth solid rgb(var(--thirdColor));
    }

    a {
        text-decoration: none;
        color: rgb(var(--textColor));

        h4 {
            @include mixins.row-limit(2);
        }
    }

    &:last-of-type {
        margin-bottom: var(--standardPadding);
    }

    @include mixins.responsive(l, above) {
        margin: inherit;
    }
}
