@use 'mixins';

.Divider {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: var(--standardPadding);
    padding: var(--standardPadding);
    line-height: 1em;
    list-style: none;
    border-bottom: 1px solid rgb(var(--sectionBorder));

    &.border {
        border-bottom: 1px solid rgb(var(--sectionBorder));
    }

    &.no-border {
        border-bottom: 0 !important;
    }

    .emphasized {
        color: rgb(var(--mainColor));

        :global(.theme-vp) & {
            color: rgb(var(--thirdColor));
        }

        :global(.theme-br) &,
        :global(.theme-gp) & {
            font-weight: var(--font-weight-bold);
        }

        h2,
        h3 {
            :global(.theme-vp) & {
                strong {
                    color: rgb(var(--textColor));
                }
            }
        }
    }
}
